.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

/* Add this to your Form.css */

.thank-you-message {
  animation: fadeIn 2s;
}

@keyframes fadeIn {
  0% {opacity: 0;}
  100% {opacity: 1;}
}

@import url('layout.css');
@import url('actions.css');
@import url('buttons.css');
@import url('cards.css');
@import url('color_palette.css');
@import url('forms.css');
@import url('modals.css');
@import url('segmented-controls.css');
@import url('shadows.css');
@import url('tabs.css');
@import url('dark_mode.css');
